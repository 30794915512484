/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/varm/cdn-assets@master/nav/js/bootstrap.min.js
 * - /gh/varm/cdn-assets@master/nav/js/TweenMax.min.js
 * - /gh/varm/cdn-assets@master/nav/js/resizeable.min.js
 * - /gh/varm/cdn-assets@master/nav/js/joinable.js
 * - /gh/varm/cdn-assets@master/nav/js/xenon-api.min.js
 * - /gh/varm/cdn-assets@master/nav/js/xenon-toggles.min.js
 * - /gh/varm/cdn-assets@master/nav/js/xenon-custom.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
